.home_item_card {
  background: var(--box_color);
  width: 100%;

  &_image {
    height: 300px;
    width: 100%;
  }

  &_title {
    color: #000000;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 20px;

    img {
      cursor: pointer;
    }

    &_container {
      padding: 20px 15px 0px;
    }
  }
  &_subtitle {
    color: #000000;
    font-size: 9px;

    &_container {
      padding: 0 15px;
    }
  }
  &_description {
    color: #000000;
    font-size: 15px;
    padding: 0 15px;
    min-height: 75px;
  }

  &_totalraise {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }

  &_totalraise_txt {
    color: #000000;
    font-size: 17px;
  }

  &_price {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }

  &_price_txt {
    color: #000000;
    font-size: 17px;
  }

  &_enddate_txt {
    color: #000000;
    font-size: 17px;
  }

  &_enddate {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }

  &_apr_txt {
    color: #000000;
    font-size: 17px;
  }

  &_apr {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }

  &_hover-box {
    position: relative;
    top: 25px;
  }

  &_button {
    background: var(--button_color);
    border-radius: 40px;
    border: 0;
    padding: 13px 50px;
    color: var(--button_txt_color);
    font-weight: bold;
    font-size: 15px;

    &_disabled {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      border-radius: 40px;
      border: 0;
      padding: 5px 35px;
      color: var(--button_txt_color);
      font-weight: bold;
      font-size: 15px;
      cursor: default !important;
    }
  }
}

.img_container {
  position: relative;
  width: 100%;

  .home_item_card_image {
    position: relative;
  }
  .fav_icon {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 20px;
    cursor: pointer;
    transition: 0.3s ease;
  }
}
