.select-box {
  position: relative;
  display: block;
  height: 45px;
  color: #000000;
  box-shadow: inset 0px 0px 0px 1px black;
  background-color: #fff;
  line-height: 25px;
  border-radius: 30px;
  cursor: pointer;
}
.select-box:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  color: #000000;
  padding-left: 10px;
}

.select-box:focus > .select-box__list {
  opacity: 1;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box:focus + .select-box__list .select-box__option {
  cursor: pointer;
}

.select-box:focus .select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 13px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.select-box__value {
  display: -webkit-box;
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: block;
}

.select-box__input-text {
  display: none;
  line-height: 30px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 40px;
}

.select-box__list {
  z-index: 1;
  background-color: #fff;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  border-left: solid 1px black;
  border-radius: 25px 25px 0 0;
  position: absolute;
  width: 100%;
  padding: 0px;
  font-size: 15px;
  color: black;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
}

.select-box__option {
  display: block;
  font-size: 15px;
  padding: 10px;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s ease;
}

.select-box__option:hover,
.select-box__option:focus {
  background: #dadada;
  color: black;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
