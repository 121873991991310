.table_item {
  padding: 0 20px;
}
.table_item_btn {
  padding: 10px;
}

.item_card_button.disabled {
  cursor: not-allowed;
}

.item_card {
  background: var(--box_color);
  height: 100%;
  width: 100%;

  &_button {
    height: 45px;
    padding: 0 25px;
    background-color: var(--button_color);
    color: var(--button_txt_color);
    border: 1px solid var(--button_border_color);
    border-radius: 40px;
    font-size: 15px;
    font-weight: bold;

    &_secondary {
      background-color: var(--secondary_button_color);
      border-radius: 40px;
      border: 1px solid var(--secondary_button_border_color);
      height: 45px;
      padding: 0 25px;
      color: var(--secondary_button_txt_color) !important;
    }
  }
}

.rewards {
  color: var(--button_color);
}

.table_content-txt {
  font-size: 17px;
}
