.loading-bg {
  height: 75px;
  width: 75px;
  position: fixed;
  z-index: 10000;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-bg > div {
  z-index: 1000;
  position: absolute;
}
.loading-bg > div > img {
  height: 75px;
  width: 75px;
}

.loading-bg:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}
