.kyc_text {
  position: relative;
  background-color: var(--secondary_button_color);
  color: var(--secondary_button_txt_color);
  border: 1px solid var(--secondary_button_border_color);
  height: 45px;
  border-radius: 40px;
  display: grid;
  text-align: center;
  align-content: center;
  padding: 0 25px;
  cursor: pointer;

  span {
    text-decoration: underline;
  }
}

.info_icon_kyc {
  margin-top: -10px;
  margin-left: 5px;
  filter: var(--navbar_icon_color);
  cursor: pointer;
}

.separator_light {
  border: 1px solid var(--separator_color);
  opacity: 1 !important;
}

.table_header_item {
  padding-left: 20px;
}

.ready_claim_text {
  padding-right: 10px;
  width: fit-content;
}

.col_0 {
  padding: 0 !important;
}
