.navbar {
  .col {
    padding: 0;
  }
  .row {
    padding: 0;
  }

  h5 {
    margin: auto;
    font-size: 13px;
  }
}

.navbar-icon {
  width: auto;
  height: 20px;
  filter: var(--navbar_icon_color);

  &.selected {
    filter: var(--navbar_icon_active_color);
  }
}

.navbar-icon-txt {
  color: var(--button_txt_color) !important;
  padding: 0 0 0 8px;
  display: none;

  &.selected {
    display: block;
  }
}

.navbar-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: var(--button_txt_color) !important;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button_color);
    border-radius: 35px;
    height: 40px;
    margin-right: 10px;
  }
}

.available_balances {
  cursor: pointer;
  height: 20px;
}

.profile_type {
  color: var(--secondary_color);
  font-weight: bold;
  text-align: end;
}

.navbar_option_profile {
  display: flex;
  flex-direction: column;
  text-align: end;
}
