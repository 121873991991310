:root {
  --background_image: url("../public/images/frontend-bg.png");
  --box_color: #fefeff;
  --main_color: #416dff;
  --progressbar: linear-gradient(180deg, #4cb2fc 0%, #4a6ef7 100%);
  --secondary_color: #416dff;
  --button_color: linear-gradient(180deg, #4cb2fc 0%, #4a6ef7 100%);
  --bg_color: #ffffff;
  --text_color: #212529;
}

@font-face {
  font-family: var(--font_family);
  src: var(--font_path) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Medium.ttf") format("truetype");
}

body,
html {
  overflow-x: hidden;
  margin: 0;
  font-family: var(--font_family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background_image) no-repeat center center fixed;
  background-color: var(--bg_color);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: fit-content;
  color: var(--text_color);
}

a {
  text-decoration: none;
}

p {
  font-weight: normal;
}

.bold {
  font-weight: bold !important;
}

.light {
  font-weight: initial !important;
}

.white_txt {
  color: #ffffff;
}

.blue_txt {
  color: var(--secondary_color);
}
.wallet_info_txt {
  color: var(--main_color);
  font-size: 15px;
}
.grey_txt {
  color: #99a0bf;
}
.center {
  display: flex;
  justify-content: center;
}

.input_container {
  display: block;
  position: relative;

  span {
    position: absolute;
    top: 7px;
    font-size: 15pt;
    right: 15px;
    color: #d9d9d9;
    font-weight: bolder;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}

.grid_center {
  display: grid;
  align-content: center;
  justify-content: center;
}

.end {
  display: flex;
  justify-content: end;
}

.only-align-center {
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.only-justify-end {
  justify-content: end;
}

.justify-end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.justify-sm-center-and-lg-start {
  display: flex;
  align-items: center;
}

.justify-sm-center-and-lg-end {
  display: flex;
  align-items: center;
}

.atyum_input {
  border: 1px solid main_color;
  background-color: transparent;
  color: #fff;
  border-radius: 40px;
  padding: 5px;
  text-align: center;
}

.atyum_input::placeholder {
  color: #fff;
  font-style: italic;
  opacity: 1;
}

.atyum_primary_btn {
  color: #fff;
  background-color: var(--main_color);
  border-radius: 40px;
  padding: 5px 30px;
  border: 0;
  font-weight: bold;
  height: 100%;
}

.atyum_primary_btn_outline {
  color: #fff;
  background-color: transparent;
  border-radius: 40px;
  padding: 5px 30px;
  border: 2px solid var(--main_color);
  font-weight: bold;
  height: 100%;
}

input {
  height: 45px;
  width: 100%;
  border-radius: 40px;
  border: 1px solid black;
  padding: 0 20px;
  background-color: #ededed;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .justify-sm-center-and-lg-start {
    justify-content: center;
  }

  .justify-sm-center-and-lg-end {
    justify-content: center;
  }

  .block-bigger {
    display: initial;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .justify-sm-center-and-lg-start {
    justify-content: center;
  }

  .justify-sm-center-and-lg-end {
    justify-content: center;
  }

  .block-bigger {
    display: initial;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .justify-sm-center-and-lg-start {
    justify-content: center;
  }

  .justify-sm-center-and-lg-end {
    justify-content: center;
  }

  .block-bigger {
    display: initial;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .justify-sm-center-and-lg-start {
    justify-content: start;
  }

  .justify-sm-center-and-lg-end {
    justify-content: end;
  }

  .block-bigger {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
