.separator {
  border: 1px solid var(--main_color);
  opacity: 1 !important;
  width: 100%;
}

.connect_btn {
  width: 100%;
  border-radius: 40px;
  background-color: transparent;
  border: 1px solid var(--main_color);
  color: var(--main_color);
  padding: 5px;
  transition: 0.3s ease-in-out;
}

.connect_btn:hover {
  background-color: var(--main_color);
  color: #ffffff;
}

.center_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.learn_more_btn {
  padding: 10px 30px;
  background: var(--button_color);
  border-radius: 40px;
  border: 0;
  color: #ffffff;
  font-weight: bold;
}