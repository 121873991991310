.my_sale_details_link_back {
  cursor: pointer;
}

.my_sale_detail_card {
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;

  &_img {
    height: auto;
    width: 150px;
    &_container {
      position: relative;
    }
  }

  &_name_distributor {
    margin: 0;
    color: #99a0bf;
  }

  &_totalraise {
    color: #000000;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }

  &_totalraise_txt {
    color: #000000;
    font-style: normal;
    font-size: 12px;
  }

  &_price {
    color: #000000;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }

  &_price_txt {
    color: #000000;
    font-style: normal;
    font-size: 9pt;
  }

  &_category {
    color: #000000;
  }

  &_date {
    color: #000000;
    text-align: center;
    
    span {
      font-weight: bold;
      font-size: 20px;
      &.primary {
        color: var(--button_color);
      }
    }
  }

  &_apr {
    color: #fff;
    background-color: #99a0bf;
    font-weight: bold;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 40px;
    font-size: 11pt;
  }

  &_separator {
    border: 1px solid #000000;
    opacity: 1;
    margin: 0;
  }

  &_graph_container {
    width: 100%;
    height: 100%;

    img {
      width: 83%;
      height: auto;
    }
  }
}

@media (max-width: 1400px) {
  .my_sale_info_container {
    padding-left: 70px;
  }
  .my_sale_detail_card_graph_container {
    img {
      width: 88%;
      height: auto;
    }
  }
}

@media (max-width: 1200px) {
  .my_sale_detail_card_img {
    height: auto;
    width: 150px;
  }
  .my_sale_info_container {
    padding-left: 110px;
  }
  .my_sale_detail_card_graph_container {
    img {
      border-radius: 20px;
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 991px) {
  .my_sale_info_container {
    padding-left: 10px;
  }
  .my_sale_detail_card_img {
    width: 100%;
  }

  .my_sale_info_container {
    margin-top: 20px;
  }

  .my_sale_detail_card_graph_container {
    height: auto;
  }
}

@media (max-width: 769px) {
  .my_sale_detail_card_img {
    height: auto;
    width: 100%;
  }
  .my_sale_detail_card_name_distributor,
  .my_sale_detail_card_whitepaper {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  .my_sale_info_item {
    padding: 0;
    a {
      padding: 0;
    }
    p {
      padding: 0;
    }

    &_logo {
      display: flex;
      justify-content: end;
    }

    &_raise {
      margin-top: 20px;
      padding-left: 40px;
    }

    &_price {
      margin-top: 20px;
    }
  }
}
