.react-slideshow-container {
    z-index: 0;
}

.react-slideshow-container .default-nav {
    background-color: #00000000;
    fill: white;
}
.react-slideshow-container .default-nav:hover, .react-slideshow-container .default-nav:focus {
    background-color: #00000000;
}