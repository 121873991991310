.progress_bar_container {
  border: var(--progressbar) solid 1px;
  background: rgb(255 255 255);
  border-radius: 50px;
  width: 100%;
  overflow: hidden;
}

.progress_bar_wrapper {
  transition: width 1s ease-in-out 0s;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_bar_label {
  color: var(--button_txt_color);
  font-size: 15px;
  font-weight: bold;
  display: initial;
}
